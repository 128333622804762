import React, { useEffect, useState } from 'react';

import './App.css';
import { useOktaAuth } from '@okta/okta-react';
import { PageLayout } from './components/PageLayout';
import { ProfileData } from './components/ProfileData';
import {ChatData} from './components/ChatData';
import Button from '@mui/material/Button';

const ProfileContent = (props) => {
 
  const {graphData,chatData } = props;
  //if(props.apiResponse) setGraphData({userPrincipalName:props.apiResponse});


  function RequestProfileData() {
      // Silently acquires an access token which is then attached to a request for MS Graph data
      props.callApi();
  }
  function RequestChat() {
    // Silently acquires an access token which is then attached to a request for MS Graph data
    props.callChatApi();
}

  return (
      <>
          <h5 className="profileContent">Welcome</h5>
          {graphData ? (
              <ProfileData graphData={graphData} />
          ) 
          :(
            <>
              <Button variant="secondary" onClick={RequestProfileData}>
                  Request Profile
              </Button>
              <Button variant="secondary" onClick={RequestChat}>
                            Testing
                        </Button>
                        </>
          )}
          {chatData ?<ChatData chatData={chatData} />:<></>}
      </>
  );
};

const MainContent = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  return (
      <div className="App">
         {(authState && authState?.isAuthenticated) ? <ProfileContent {...props}/> : <h5 className="card-title">Please sign-in to see your profile information.</h5>}
      </div>
  );
};

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [apiResponse, setApiResponse] = useState('');
  const [graphData, setGraphData] = useState(null);
  const [chatData, setChatData] = useState('');
  
  const callApi = async () => {
    if (authState.isAuthenticated) {
      const accessToken = await oktaAuth.getAccessToken();
      console.log('accessToken',accessToken);
      console.log('authState',authState);

      const response = await fetch('https://testapp120241106100346.azurewebsites.net/api/values', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify( authState )
      });
      const data = await response.json();
      setApiResponse(data.name);
      setGraphData(data);
    }
  };

  const callChatApi = () => { fetch("https://testapp120241106100346.azurewebsites.net/api/RAGChat/postquestion",
    {
      method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({question:'who is sitting on board of directors?'})
    }).then(async response => {
         console.log('----Get Response------');
         const reader = response.body?.getReader();
         var testresult = '';
         if (!reader) {
          throw new Error('Failed to read response');
        }
         const decoder = new TextDecoder();
         var i = 0;
         while (true) {
             console.log('====count====',i);
             i++;
             const { done, value } = await reader.read();
             if (done) break;
             if (!value) continue;
             console.log('value',value);
             var item = decoder.decode(value).replace(/\[|]/g, '').replace(/^,/, '');
             //var item = decoder.decode(value);
             console.log('item',item);
             //setChatData(chatData.concat(" ", item));
             testresult = testresult.concat(" ", item);
             console.log('chatData',i, testresult);
             //var parsedItem = JSON.parse(item);
             //console.log('parsedItem',parsedItem);
             //chatData.add(parsedItem);
             //setChatData(testresult);
        }
        reader.releaseLock();
        console.log('---done---');
    })
    }


  if (!authState) {
    return (
      <div>Loading...</div>
    );
  }

  return (
    <PageLayout>
        <MainContent callApi={callApi} callChatApi={callChatApi}  apiResponse={apiResponse} graphData={graphData} chatData={chatData}/>
    </PageLayout>
);
};

export default Home;
