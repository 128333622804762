import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ChatData = (anws) => {
    return (
        <Box sx={{ width: '100%', maxWidth: 500 }}>
        <Typography variant="body1" gutterBottom>
            {anws}
        </Typography>
        </Box>
    );
};
